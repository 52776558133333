<template>
    <v-sheet class="field-group mb-4 rounded-lg">
        <v-row class="py-0">
            <v-col cols="12">
                <!-- <code>{{field.id}}</code> -->
                <div class="title font-weight-black mb-2">{{i18n(field, 'label')}}</div>
                <div class="body-1">{{i18n(field, 'desc')}}</div>

                <v-alert
                    v-if="field.info"
                    color="blue-grey"
                    dark
                    dense
                    type="info"
                    prominent
                >
                    {{i18n(field, 'info')}}
                </v-alert>
            </v-col>

            <v-col
                v-for="f in field.fields"
                :key="f.id"
                cols="12"
                class="mt-0 py-0"
                :xs="f.width ? f.width.xs : 12"
                :sm="f.width ? f.width.sm : 12"
                :md="f.width ? f.width.md : 12"
                :lg="f.width ? f.width.lg : 12"
                :xl="f.width ? f.width.xl : 12"
            >
                <div :key="f.id">
                    <field-types :form="form" :field="f"></field-types>
                </div>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>

    export default {
        name: 'field-group',
        components: {
            FieldTypes: () => import('./FieldTypes.vue')
        },
        props: {
            field: {
                type: Object,
                required: true
            },
            form: {
                type: Object,
                required: true
            }
        },
        data: () => ({
        }),
        mounted () {
            // console.log(this.field.fields)
        },
        methods: {
            i18n (obj, key) {
                if (obj.i18n) {
                    if (obj.i18n[this.form.data.language]) {
                        if (obj.i18n[this.form.data.language][key]) {
                            return obj.i18n[this.form.data.language][key];
                        }
                    }
                }
                return obj[key];
            }
        }
    }
</script>
