import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'

import Agriplanta from '../views/Agriplanta.vue'
import Agraria from '../views/Agraria.vue'
import AgrariaMultumim from '../views/AgrariaMultumim.vue'
import AgriplantaMultumim from '../views/AgriplantaMultumim.vue'
import DateAgraria from '../views/DateAgraria.vue'
import DateAgriplanta from '../views/DateAgriplanta.vue'
import Login from '../views/LoginView.vue'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [{
        path: '/agriplanta',
        name: 'agriplanta',
        component: Agriplanta
    }, {
        path: '/agraria',
        name: 'agraria',
        component: Agraria
    }, {
        path: '/agraria-multumim',
        name: 'Agraria multumim',
        component: AgrariaMultumim
    }, {
        path: '/agriplanta-multumim',
        name: 'Agriplanta multumim',
        component: AgriplantaMultumim
    }, {
        path: '/date-agraria',
        name: 'date-agraria',
        component: DateAgraria
    }, {
        path: '/date-agriplanta',
        name: 'date-agriplanta',
        component: DateAgriplanta
    }, {
        path: '/login',
        name: 'login',
        component: Login
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
